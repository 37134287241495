<template>
  <b-card data-style="custom">
    <!--begin: Role Permission Selection Table-->
    <RolePermissionSelectionTable
      :isNew="true"
      @submit="createNewRole"
    ></RolePermissionSelectionTable>
    <!--end: Role Permission Selection Table-->
  </b-card>
</template>

<script>
  import RolePermissionSelectionTable from '@/views/components/role-permission/RolePermissionSelectionTable';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    ROLE_CREATE_ROLE,
    ROLE_INITIAL_CREATE_ROLE_STATE
  } from '@/core/store/role.module';

  export default {
    name: 'RolePermissionNew',
    mixins: [commonMixin, constantsMixin],
    components: {
      RolePermissionSelectionTable
    },
    data: () => ({
      routeName: ROUTE_NAME
    }),
    computed: {
      newRoleComplete() {
        return this.$store.state.role.newRole.complete;
      }
    },
    watch: {
      newRoleComplete() {
        let response = this.$store.state.role.newRole;
        if (response.complete) {
          this.createNewRoleCompleteAction(response);
        }
      }
    },
    created() {
      this.initialRoleNew();
    },
    methods: {
      createNewRoleCompleteAction(response) {
        let title = i18nHelper.getMessage('label.createNewRole');
        let messages = response.message;
        let buttons = [];

        if (response.code == 200) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialCreateRoleState();
              this.getConstants();
              this.$router.push({ name: ROUTE_NAME.ROLE_PERMISSION_LIST });
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialCreateRoleState();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      getConstants() {
        this.$root.$children[0].$refs.layout.getConstants();
      },
      createNewRole(data) {
        this.createRole(data);
      },
      createRole(data) {
        this.$store.dispatch(ROLE_CREATE_ROLE, { data });
      },
      initialCreateRoleState() {
        this.$store.dispatch(ROLE_INITIAL_CREATE_ROLE_STATE);
      },
      initialRoleNew() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.rolePermission'),
              route: { name: ROUTE_NAME.ROLE_PERMISSION }
            },
            { title: i18nHelper.getMessage('label.rolePermissionNew') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style lang="scss"></style>
